import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import SanityLink from '~components/SanityLink'
import styled from '@emotion/styled'
import useSiteSettings from '~utils/useSiteSettings'
import resolveLink from '~utils/resolveLink'
import { Hamburger, LogoSimple, Cross, LogoIcon, SpeechBubble, InstaLogo } from '~components/Svg'
import {  mobile, tablet } from '~styles/global'
import Button from '~components/Button'
import { useSiteState } from '~context/siteContext'
import RichText from '~components/RichText'
import { motion, AnimatePresence } from 'framer-motion'

const Header = ({ className, minimal, artist, contactPage }) => {
	const { mainMenu, contactButtonText, instagramUrl } = useSiteSettings()
	const [siteState, setSiteState] = useSiteState()
	const [top, setTop] = useState(true)
	const [hover, setHover] = useState(false)
	const [hoverIndex, setHoverIndex] = useState(undefined)

	useEffect(() => {
		if(window && window.pageYOffset > 60){
			setTop(false)
		}
		window.addEventListener('scroll', () => {
			if (window.pageYOffset < 60){
				setTop(true)
			} else {
				setTop(false)
			}
		})
		return () => window.removeEventListener('scroll', () => {
			if (window.pageYOffset < 60){
				setTop(true)
			} else {
				setTop(false)
			}
		})
	}, [])

	const setHoverAndIndex = i => {
		setHover(true)
		setHoverIndex(i)
	}

	return(
		<>
			<Wrap className={className} ackOpen={siteState.acknowledgement}>
				<Inner fixed={artist} top={top} bgColor={siteState.topBgColor}>
					<ButtonLayer artist={artist}>
						<MenuButton 
							onClick={() => setSiteState(prevState =>({
								...prevState,
								menuOpen: !siteState.menuOpen
							}))}>
							{ !siteState.acknowledgement &&
								<>
									<HamburgerIcon show={!siteState.menuOpen}/>
									<Close show={siteState.menuOpen}/>
								</>
							}
						</MenuButton>
						<Left />
						<Right>
							{!contactPage &&
								<ContactButton 
									hide={minimal} 
									open={siteState.menuOpen}
									bgColor={siteState.color === 'var(--black)' ? 'var(--black)' : siteState.topBgColor}
									className='xs' 
									color={siteState.CTA}
									link={{
										linkType: 'internal', 
										document:{
											slug: {
												current: 'contact'
											}
										}
									}}
								>
									{contactButtonText} <SpeechBubbleIcon />
								</ContactButton>
							}
						</Right>
					</ButtonLayer>
					<LogoLayer>
						<Logo to='/' open={siteState.menuOpen} hide={minimal} top={top}>
							<LogoDesk />
							<LogoMob />
						</Logo>
					</LogoLayer>
				</Inner>
			</Wrap>
			<Menu open={siteState.menuOpen}>
				<MenuTop>
					<Nav>
						{mainMenu.map((item, i) => (
							<MenuLinkRow 
								key={item._key}
							>
								<motion.div 
									initial="hidden"
									animate={siteState.menuOpen ? 'visible' : 'hidden'}
									variants={{
										visible: { 
											opacity: 1,	
											transition: {
												delay: (i + 1) * 0.1,
												duration: 0.6
											} 
										},
										hidden: { opacity: 0}
									}}
								>
									<Hover
										onMouseEnter={() => setHoverAndIndex(i)}
										onMouseLeave={() => setHover(false)}
										fade={hover && i !== hoverIndex}
									>
										<MenuLink className='h1' link={item.link} activeClassName='active'>
											{item.text}
										</MenuLink>
									</Hover>
								</motion.div>
							</MenuLinkRow>
						))}
						<MenuLinkRowLast>
							<motion.div 
								initial="hidden"
								animate={siteState.menuOpen ? 'visible' : 'hidden'}
								variants={{
									visible: { 
										opacity: 1,	
										transition: {
											delay: 0.6,
											duration: 0.6
										} 
									},
									hidden: { opacity: 0}
								}}
							>
								<Hover
									onMouseEnter={() => setHoverAndIndex('instagram')}
									onMouseLeave={() => setHover(false)}
									fade={hover && 'instagram' !== hoverIndex}
								>
									<InstaLink 
										link={{
											linkType: 'external',
											url: instagramUrl,
											blank: true
										}}>
										<Instagram />
									</InstaLink>
								</Hover>
							</motion.div>
						</MenuLinkRowLast>
					</Nav>
				</MenuTop>
			</Menu>
		</>
	)
}

const Wrap = styled.header`
	position: relative; 
	height: 100px;
	box-sizing: border-box;
	z-index: 4;
	pointer-events: none;
	color: ${ props => props.ackOpen ? 'var(--orange)' : 'inherit'};
	${tablet}{
		height: 82px;
	}
	${mobile}{
		height: 78px;
	}
`
const Inner = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	${mobile}{
		height: 78px;
		/* position: ${ props => props.fixed ? 'fixed' : 'relative'}; */
		position: relative;
		/* background-color: ${ props => !props.top && props.fixed ? props.bgColor : 'transparent' }; */
		background-color: transparent;
		/* border-bottom: 1px solid; */
		border-bottom:  none;
		/* border-color: ${ props => !props.top && props.fixed ? 'var(--black)' : 'transparent' }; */
		/* transition: background-color 0.25s, border-color 0.25s; */
	}
`
const ButtonLayer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	padding: var(--m);
	z-index: 2;
	pointer-events: none;
	${mobile}{
		justify-content: ${ props => props.artist ? 'space-between' : 'start'};
	}
`
const MenuButton = styled.button`
	width: ${ props => props.open ? '28px' : '33px'};
	height: ${ props => props.open ? '28px' : '33px'};
	position: fixed;
	pointer-events: all;
	top: 31px;
	${mobile}{
		top: 25px;
	}
`
const HamburgerIcon = styled(Hamburger)`
	opacity: ${ props => props.show ? '1' : '0'};
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
`
const Close = styled(Cross)`
	opacity: ${ props => props.show ? '1' : '0'};
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	color: var(--orange);
`
const LogoLayer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	pointer-events: none;
`
const Logo = styled(Link)`
	width: 289px;
	display: inline-block;
	pointer-events: ${ props => props.open || props.hide ? 'none' : 'all'};
	opacity: ${ props => props.open || props.hide ? '0' : '1'};
	transition: opacity 0.3s;
	max-height: 39px;
	${mobile}{
		max-height: none;
		width: 48px;
		opacity: ${ props => props.open || props.hide ? '0' : '1'}; 
	}
`
const LogoDesk = styled(LogoSimple)`
	${mobile}{
		display: none;
	}
`
const LogoMob = styled(LogoIcon)`
	display: none;
	${mobile}{
		display: block;
	}
`
const Left = styled.div`
	
`
const Right = styled.div`
	
`
const ContactButton = styled(SanityLink)`
	opacity: ${ props => props.open || props.hide ? '0' : '1'};
	pointer-events: ${ props => props.open || props.hide ? 'none' : 'all'};
	white-space: nowrap;
	border: 3px solid;
	border-radius: 8px;
	text-transform: uppercase;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	height: 40px;
	padding: 11px 15px 9px;
	box-sizing: border-box;
	min-width: 169px;
	letter-spacing: 0.04em;
	color: ${ props => props.color};
	transition: color 0.25s, background-color 0.25s, border-color 0.25s, opacity 0.3s;
	background-color: ${ props => props.color};
	border-color: ${ props => props.color};
	color: ${ props => props.bgColor};
	position: relative;
	top: -5px;
	:hover{
		color: ${ props => props.color};
		background-color: ${ props => props.bgColor};
		border-color: ${ props => props.color}
	}
	${mobile}{
		display: none;
	}
`
const SpeechBubbleIcon = styled(SpeechBubble)`
	width: 23px;
	margin-left: 12px;
`
const Menu = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 100vh;
	z-index: 3;
	opacity: ${ props => props.open ? '1' : '0'};
	pointer-events: ${ props => props.open ? 'all' : 'none'};
	transition: opacity 0.3s;
	background: var(--gradientPinkBlue);
	display: flex;
	flex-direction: column;
	color: var(--orange);
	padding: 0 var(--xxl) 0;
	box-sizing: border-box;
	overflow-y: scroll;
`
const MenuTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
`
const Ack = styled.div`
	max-width: 1340px;
`
const Nav = styled.div`
	padding: var(--xl) 0;
`

const MenuLinkRow = styled.div`
	text-align: center;
`
const Hover = styled.div`
	display: inline-block;
	opacity: ${ props => props.fade ? '0.2' : '1'};
	transition: opacity 0.6s;
`
const MenuLink = styled(SanityLink)`
	margin-bottom: 25px;
	display: inline-block;
	position: relative;
	:after{
		content:'';
		height: 6px;
		position: absolute;
		left: 0;
		right: 0;
		top: calc(100% + 2px);
		background-color: transparent;
		transition: background-color 0.3s;
		width: 97%;
		margin: 0 auto;
		${tablet}{
			height: 3px;
		}
	}
	&.active{
		:after{
			background-color: var(--orange);
		}
	}
`
const MenuLinkRowLast = styled(MenuLinkRow)`
	margin-top: 40px;	
`
const Instagram = styled(InstaLogo)`
	width: 46px;
	${tablet}{
		width: 32px;
	}
`
const InstaLink = styled(SanityLink)`
`
Header.propTypes = {
	className: PropTypes.string,
	minimal: PropTypes.bool,
	artist: PropTypes.bool,
	contactPage: PropTypes.bool,
}

export default Header
